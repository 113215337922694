import React from 'react';
import { useLocation } from 'react-router-dom';
import Lottie from 'lottie-react';
import loader from '../../assets/loader.json';

const Loader = () => {
  const { pathname } = useLocation();

  return (
    <div className='loader flex align-center justify-center'>
      {pathname.includes('/app/') ? (
        <Lottie animationData={loader} loop={true} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Loader;
