import ApiClient from './base';

const UserAPI = {
  getCurrentUser: () => ApiClient.get('/user/me/'),
  getAllUsers: ({ page, pageSize, order, filter, signal }) =>
    ApiClient.get(
      `/user/all?page=${page}&per_page=${pageSize}${
        order?.type ? `&order=${order?.item}${order?.type}` : ''
      }${filter?.value ? `&filter[${filter?.key}]=${filter.value}` : ''}`,
      { signal },
    ),
  getUserFolders: ({ userId, page, pageSize, order, filter, signal }) =>
    ApiClient.get(
      `/user/${userId}/folder?page=${page}&per_page=${pageSize}${
        order?.type ? `&order=${order?.item}${order?.type}` : ''
      }${filter?.value ? `&filter[${filter?.key}]=${filter.value}` : ''}`,
      {
        signal,
      },
    ),
};

export default UserAPI;
