export const getAccessToken = () => {
  const token = localStorage.getItem('jwt-access-token');
  return token;
};

export const getRefreshToken = () => {
  const token = localStorage.getItem('jwt-refresh-token');
  return token;
};

export const setAccessToken = (access, storage = localStorage) => {
  storage.setItem('jwt-access-token', access);
};

export const setToken = async (access, refresh, storage = localStorage) => {
  storage.setItem('jwt-access-token', access);
  storage.setItem('jwt-refresh-token', refresh);
  await null;
};

export const setRemember = (value) => {
  localStorage.setItem('remember-user', value);
};

export const getRememberValue = () => {
  return localStorage.getItem('remember-user');
};

export const clearStorage = () => {
  localStorage.clear();
};
