import axios from 'axios';
import {
  clearStorage,
  getAccessToken,
  getRefreshToken,
  setAccessToken,
} from 'helpers/storage';
import { useAuthStore } from 'state.manager/auth.store';
import { useUserStore } from 'state.manager/user.store';

const baseConfig = {
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};

const ApiClient = axios.create(baseConfig);

let isAlreadyRetried = false;
let subscribedActions = [];

const invokeSubscribedActions = (accessToken) => {
  subscribedActions.map((action) => action(accessToken));
};

const requestOnFullFilled = (config) => {
  const token = getAccessToken();
  if (token && config.url !== '/access_token') {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

const requestOnRejected = (error) => {
  return Promise.reject(error);
};

const refreshToken = async () => {
  try {
    isAlreadyRetried = true;
    const result = await ApiClient.get(
      `/access_token?refresh_token=${getRefreshToken()}`,
    );
    setAccessToken(result.data.access_token);
    invokeSubscribedActions(result.data.access_token);
    useAuthStore.getState().setAuthState('auth');
  } catch (err) {
    useAuthStore.getState().setAuthState('not-auth');
    clearStorage();
    useUserStore
      .getState()
      .setCurrentUser({ email: '', role: '', username: '' });
  }
  subscribedActions = [];
  isAlreadyRetried = false;
};

function addSubscriber(callback) {
  subscribedActions.push(callback);
}

const responseOnRejected = async (error) => {
  if (error.response?.data?.errors[0] === 'Unauthorized') {
    useAuthStore.getState().setAuthState('not-auth');
  }
  if (error?.response?.data?.errors[0] === 'Token has expired') {
    const originalRequest = error.config;
    if (!isAlreadyRetried) {
      refreshToken();
    }
    const retryOriginalRequest = new Promise((resolve) => {
      addSubscriber((accessToken) => {
        originalRequest.headers.Authorization = 'Bearer ' + accessToken;
        resolve(ApiClient(originalRequest));
      });
    });
    return retryOriginalRequest;
  }
  return Promise.reject(error);
};

ApiClient.interceptors.request.use(requestOnFullFilled, requestOnRejected);

ApiClient.interceptors.response.use((res) => res, responseOnRejected);

export default ApiClient;
