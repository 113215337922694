const ROOTS_AUTH = '/app/auth';
const ROOTS_DASHBOARD = '/app/dashboard';

const path = (root, sublink) => `${root}${sublink}`;

export const PATHS_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  forgotPassword: path(ROOTS_AUTH, '/forgot-password'),
  resetPassword: path(ROOTS_AUTH, '/reset_password'),
};

export const EMAIL_CONFIRMATION = '/app/email_confirmation';
export const NOT_FOUND = '/app/not_found';
export const NOT_VALID_LINK = '/app/not_valid_link';
export const SHORT_LINK = '/:backHalf';

export const PATHS_DASHBOARD = {
  general: path(ROOTS_DASHBOARD, '/general'),
  createNew: path(ROOTS_DASHBOARD, '/create'),
  links: path(ROOTS_DASHBOARD, '/links'),
  folders: path(ROOTS_DASHBOARD, '/folders'),
  userDetails: path(ROOTS_DASHBOARD, '/user/:userId'),
  folder: path(ROOTS_DASHBOARD, '/folders/:folder_id'),
  leads: path(ROOTS_DASHBOARD, '/leads'),
  leadDetails: path(ROOTS_DASHBOARD, '/leads/:leadId'),
};
