import { lazy } from 'react';
import { useRoutes, Navigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import {
  EMAIL_CONFIRMATION,
  NOT_FOUND,
  NOT_VALID_LINK,
  PATHS_AUTH,
  PATHS_DASHBOARD,
  SHORT_LINK,
} from './paths';
import ProtectedRoute from 'components/hoc/ProtectedRoute';
import { useAuthStore } from 'state.manager/auth.store';
import { useUserStore } from 'state.manager/user.store';
import UserAPI from 'services/api/user';

const Router = () => {
  const { authState, setAuthState, setAuthError } = useAuthStore();
  const { setCurrentUser, currentUser } = useUserStore();

  useQuery(['get-current-user'], async () => await UserAPI.getCurrentUser(), {
    onSuccess: (data) => {
      setAuthState('auth');
      setCurrentUser(data.data.user);
    },
    onError: (err) => {
      if (err.code !== 401 && err?.response?.status !== 401) {
        setAuthState('not-auth');
        setCurrentUser({ email: '', role: '', username: '' });
        setAuthError({
          exists: true,
          message: 'This is a system error. Please try again in a while',
        });
      }
    },
  });

  return useRoutes([
    {
      path: 'app/auth',
      element: (
        <ProtectedRoute requiredAuthState={'not-auth'}>
          <AuthLayout />
        </ProtectedRoute>
      ),
      children: [
        { path: '', element: <Navigate to={PATHS_AUTH.login} /> },
        { path: PATHS_AUTH.login, element: <LogIn /> },
        { path: PATHS_AUTH.register, element: <Register /> },
        { path: PATHS_AUTH.forgotPassword, element: <ForgotPassword /> },
        { path: PATHS_AUTH.resetPassword, element: <ResetPassword /> },
      ],
    },
    { path: EMAIL_CONFIRMATION, element: <EmailConfirmation /> },
    { path: NOT_FOUND, element: <NotFound /> },
    {
      path: 'app/dashboard',
      element: (
        <ProtectedRoute requiredAuthState={'auth'}>
          <DashboardLayout />
        </ProtectedRoute>
      ),
      children: [
        { path: '', element: <Navigate to={PATHS_DASHBOARD.general} /> },
        { path: PATHS_DASHBOARD.general, element: <Dashboard /> },
        { path: PATHS_DASHBOARD.createNew, element: <CreateNew /> },
        { path: PATHS_DASHBOARD.links, element: <Links /> },
        { path: PATHS_DASHBOARD.userDetails, element: <UserDetails /> },
        {
          path: PATHS_DASHBOARD.folders,
          element:
            currentUser.role === 'super_admin' ? (
              <Folders />
            ) : (
              <Navigate to={PATHS_DASHBOARD.general} />
            ),
        },
        { path: PATHS_DASHBOARD.folder, element: <FolderDetails /> },
        { path: PATHS_DASHBOARD.leads, element: <Leads /> },
        { path: PATHS_DASHBOARD.leadDetails, element: <LeadDetails /> },
      ],
    },
    {
      path: '',
      element: (
        <Navigate
          to={authState === 'auth' ? PATHS_DASHBOARD.general : PATHS_AUTH.login}
        />
      ),
    },
    {
      path: '/app',
      element: (
        <Navigate
          to={authState === 'auth' ? PATHS_DASHBOARD.general : PATHS_AUTH.login}
        />
      ),
    },
    {
      path: '*',
      element: <NotFound />,
    },
    {
      path: NOT_VALID_LINK,
      element: <NotValidLink />,
    },
    {
      path: SHORT_LINK,
      element: <ShortLinkHandler />,
    },
  ]);
};

export default Router;

const DashboardLayout = lazy(() => import('layouts/DashboardLayout'));
const AuthLayout = lazy(() => import('layouts/AuthLayout'));

const ForgotPassword = lazy(() => import('pages/auth/ForgotPassword'));
const ResetPassword = lazy(() => import('pages/auth/ResetPassword'));
const Register = lazy(() => import('pages/auth/Register'));
const LogIn = lazy(() => import('pages/auth/LogIn'));

const FolderDetails = lazy(() => import('pages/dashboard/FolderDetails'));
const UserDetails = lazy(() => import('pages/dashboard/UserDetails'));
const LeadDetails = lazy(() => import('pages/dashboard/LeadDetails'));
const Dashboard = lazy(() => import('pages/dashboard/Dashboard'));
const CreateNew = lazy(() => import('pages/dashboard/CreateNew'));
const Links = lazy(() => import('pages/dashboard/Links'));
const Leads = lazy(() => import('pages/dashboard/Leads'));
const Folders = lazy(() => import('components/folders'));

const EmailConfirmation = lazy(() => import('pages/auth/emailConfirmation'));
const NotValidLink = lazy(() => import('pages/general/NotValidLink'));
const NotFound = lazy(() => import('pages/general/NotFound'));
const ShortLinkHandler = lazy(() => import('pages/general/shortLinkHandler'));
