import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useAuthStore } from 'state.manager/auth.store';
import { PATHS_AUTH, PATHS_DASHBOARD } from 'routes/paths';

const ProtectedRoute = ({ requiredAuthState, children }) => {
  const { authState } = useAuthStore();
  const REDIRECT_PATH =
    requiredAuthState === 'auth' ? PATHS_AUTH.login : PATHS_DASHBOARD.general;

  if (authState !== 'pending') {
    if (authState === requiredAuthState) {
      return children;
    } else {
      return <Navigate to={REDIRECT_PATH} />;
    }
  } else {
    return null;
  }
};

ProtectedRoute.propTypes = {
  requiredAuthState: PropTypes.string,
  children: PropTypes.node,
};

export default ProtectedRoute;
